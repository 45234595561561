import {
  FlexContainer,
  GridContainer,
  getColor,
  getSpace,
} from '@aid-module/ui';
import styled from '@emotion/styled';
import { ActionsContainerEmotionViewProps } from './types';

export const Container = styled(FlexContainer)`
  padding: ${getSpace('8')} ${getSpace('60')} ${getSpace('8')} ${getSpace('20')};
  border-bottom: 1px solid ${getColor('main-deep-2')};
  position: relative;
`;

export const ActionsContainer = styled(
  GridContainer
)<ActionsContainerEmotionViewProps>`
  grid-template-columns: repeat(
    ${({ canShowGlobalSearch }) => (canShowGlobalSearch ? '5' : '4')},
    max-content
  );
`;
