import {
  FEATURES,
  PERMISSIONS,
  PERMISSIONS_CM_CASES,
  PERMISSIONS_SUBMISSIONS,
} from "@aid-package/context";
import { Route, Routes, Navigate } from "react-router-dom";
import {
  VerificationDescription,
  VerificationsRegister,
} from "./Verifications";
import { CaseDescription, CasesRegister } from "./Cases";
import {
  ProtectedRoute,
  ErrorPages,
  ProtectedFeaturesRoute,
} from "@aid-module/ui";
import { RedirectVerificationFlow } from "./redirect";
import {
  IdentityVerificationDescription,
  IdentityVerificationsRegister,
} from "./IdentityVerifications";
import { ReminderSettingsRegister } from "./ReminderSettings";
import {
  CustomerDocumentsRegister,
  CustomerDocumentDescription,
} from "./CustomerDocuments";
import {
  EmailTemplateCreate,
  EmailTemplateUpdate,
  EmailTemplatesRegister,
} from "./EmailTemplates";
import {
  CustomerMessageCreate,
  CustomerMessageUpdate,
  CustomerMessagesRegister,
} from "./CustomerMessages";
import { ReminderTemplatesRegister } from "./ReminderTemplates";
import { AISummaryConfigRegister } from "./AISummaryConfigs";
import { EDDReportDescription, EDDReportsRegister } from "./EddReports";
import { DataProfileDescription, DataProfilesRegister } from "./DataProfiles";

export function VerificationRouter() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute
            check={[
              PERMISSIONS.SEE_CM_CASES_LIST,
              PERMISSIONS.SEE_SUBMISSIONS_LIST,
              PERMISSIONS.SEE_SUBMISSION,
              PERMISSIONS.SEE_SUBMISSION_CONFIDENTIAL,
              PERMISSIONS.FORMS_CREATE,
              PERMISSIONS.FORMS_PUBLISH,
              PERMISSIONS.FORMS_UPDATE,
              PERMISSIONS.SEE_SHARED_CASES,
              PERMISSIONS.SEE_CM_CASES,
              PERMISSIONS.MANAGE_CM_CASES,
              PERMISSIONS.SEE_APPROVED_CM_CASES,
              PERMISSIONS.SUBMISSIONS_SEE_OTHERS,
              PERMISSIONS.SUBMISSIONS_UPDATE_OTHERS,
              PERMISSIONS.REMINDER_SETTINGS,
              PERMISSIONS.MANAGE_CUSTOMER_DOCUMENTS,
              PERMISSIONS.MANAGE_AI,
              PERMISSIONS.MANAGE_EDD_REPORTS,
            ]}
          />
        }
      >
        // Redirect verification flows Start
        <Route
          path="/verification_flows"
          element={
            <ProtectedRoute
              check={[
                PERMISSIONS.FORMS_CREATE,
                PERMISSIONS.FORMS_PUBLISH,
                PERMISSIONS.FORMS_UPDATE,
              ]}
            />
          }
        >
          <Route index element={<RedirectVerificationFlow />} />
          <Route path="form" element={<RedirectVerificationFlow />} />
          <Route path="form/:id" element={<RedirectVerificationFlow />} />
        </Route>
        // Redirect verification flows End
        <Route
          path="verifications"
          element={<ProtectedRoute check={PERMISSIONS_SUBMISSIONS} />}
        >
          <Route index element={<VerificationsRegister />} />
          <Route path=":id" element={<VerificationDescription />} />
        </Route>
        <Route
          path="reminder_settings"
          element={<ProtectedRoute check={[PERMISSIONS.REMINDER_SETTINGS]} />}
        >
          <Route index element={<ReminderSettingsRegister />} />
        </Route>
        <Route
          path="reminder_templates"
          element={<ProtectedRoute check={[PERMISSIONS.REMINDER_SETTINGS]} />}
        >
          <Route index element={<ReminderTemplatesRegister />} />
        </Route>
        <Route
          path="cases"
          element={<ProtectedRoute check={PERMISSIONS_CM_CASES} />}
        >
          <Route index element={<CasesRegister />} />
          <Route path=":id" element={<CaseDescription />} />
        </Route>
        <Route
          path="data_profiles"
          element={<ProtectedRoute check={PERMISSIONS.MANAGE_DATA_PROFILE} />}
        >
          <Route index element={<DataProfilesRegister />} />
          <Route path=":id/*" element={<DataProfileDescription />}>
            <Route path="*" element={<Navigate to="." replace />} />
          </Route>
        </Route>
        <Route
          path="edd_reports"
          element={<ProtectedRoute check={PERMISSIONS.MANAGE_EDD_REPORTS} />}
        >
          <Route index element={<EDDReportsRegister />} />
          <Route path=":id" element={<EDDReportDescription />} />
        </Route>
        <Route
          path="customer_documents"
          element={
            <ProtectedRoute check={[PERMISSIONS.MANAGE_CUSTOMER_DOCUMENTS]} />
          }
        >
          <Route index element={<CustomerDocumentsRegister />} />
          <Route path=":id" element={<CustomerDocumentDescription />} />
        </Route>
        <Route
          path="identity_verifications"
          element={
            <ProtectedRoute
              check={[
                PERMISSIONS.CM_IDENTITY_VERIFICATIONS,
                PERMISSIONS.MANAGE_CM_CASES,
                PERMISSIONS.SEE_APPROVED_CM_CASES,
              ]}
            />
          }
        >
          <Route index element={<IdentityVerificationsRegister />} />
          <Route path=":id" element={<IdentityVerificationDescription />} />
        </Route>
        <Route
          path="email_templates"
          element={
            <ProtectedRoute check={[PERMISSIONS.MANAGE_EMAIL_TEMPLATES]} />
          }
        >
          <Route index element={<EmailTemplatesRegister />} />
          <Route path="form">
            <Route
              element={
                <ProtectedFeaturesRoute check={FEATURES.CUSTOMIZATION_TOOLS} />
              }
            >
              <Route index element={<EmailTemplateCreate />} />
            </Route>
            <Route path=":id" element={<EmailTemplateUpdate />} />
          </Route>
        </Route>
        <Route
          path="customer_messages"
          element={
            <ProtectedRoute
              check={[
                PERMISSIONS.SEE_CUSTOMER_MESSAGES,
                PERMISSIONS.MANAGE_CUSTOMER_MESSAGES,
              ]}
            />
          }
        >
          <Route index element={<CustomerMessagesRegister />} />
          <Route path="form">
            <Route
              element={
                <ProtectedFeaturesRoute check={FEATURES.CUSTOMIZATION_TOOLS} />
              }
            >
              <Route index element={<CustomerMessageCreate />} />
            </Route>
            <Route path=":id" element={<CustomerMessageUpdate />} />
          </Route>
        </Route>
        <Route
          path="ai_summary_configs"
          element={<ProtectedRoute check={[PERMISSIONS.MANAGE_AI]} />}
        >
          <Route index element={<AISummaryConfigRegister />} />
        </Route>
        <Route path="*" element={<ErrorPages type="local" code={404} />} />
      </Route>
    </Routes>
  );
}

export { VerificationsRegister };
