import { useCallback } from 'react';

export function useGoToBeta() {
  const onClickButton = useCallback(() => {
    window.location.href = `${process.env.REACT_APP_BETA_HOST}${window.location.pathname}`;
  }, []);
  return {
    onClickButton
  };
}
