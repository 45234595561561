import { CountriesIcons } from '../components/Icon/CountriesIcon/component/components/countries/types';

export const CountriesObject: {
  [key: string]: {
    label: string;
    value: string;
    flag: keyof typeof CountriesIcons;
    label_alpha_3: string;
    value_alpha_3: string;
  };
} = {
  af: {
    label: 'Afghanistan',
    value: 'af',
    flag: 'AF',
    label_alpha_3: 'AFG - Afghanistan',
    value_alpha_3: 'AFG',
  },
  al: {
    label: 'Albania',
    value: 'al',
    flag: 'AL',
    label_alpha_3: 'ALB - Albania',
    value_alpha_3: 'ALB',
  },
  dz: {
    label: 'Algeria',
    value: 'dz',
    flag: 'DZ',
    label_alpha_3: 'DZA - Algeria',
    value_alpha_3: 'DZA',
  },
  ad: {
    label: 'Andorra',
    value: 'ad',
    flag: 'AD',
    label_alpha_3: 'AND - Andorra',
    value_alpha_3: 'AND',
  },
  ao: {
    label: 'Angola',
    value: 'ao',
    flag: 'AO',
    label_alpha_3: 'AGO - Angola',
    value_alpha_3: 'AGO',
  },
  ag: {
    label: 'Antigua And Barbuda',
    value: 'ag',
    flag: 'AG',
    label_alpha_3: 'ATG - Antigua And Barbuda',
    value_alpha_3: 'ATG',
  },
  ar: {
    label: 'Argentina',
    value: 'ar',
    flag: 'AR',
    label_alpha_3: 'ARG - Argentina',
    value_alpha_3: 'ARG',
  },
  am: {
    label: 'Armenia',
    value: 'am',
    flag: 'AM',
    label_alpha_3: 'ARM - Armenia',
    value_alpha_3: 'ARM',
  },
  aw: {
    label: 'Aruba',
    value: 'aw',
    flag: 'AW',
    label_alpha_3: 'ABW - Aruba',
    value_alpha_3: 'ABW',
  },
  au: {
    label: 'Australia',
    value: 'au',
    flag: 'AU',
    label_alpha_3: 'AUS - Australia',
    value_alpha_3: 'AUS',
  },
  at: {
    label: 'Austria',
    value: 'at',
    flag: 'AT',
    label_alpha_3: 'AUT - Austria',
    value_alpha_3: 'AUT',
  },
  az: {
    label: 'Azerbaijan',
    value: 'az',
    flag: 'AZ',
    label_alpha_3: 'AZE - Azerbaijan',
    value_alpha_3: 'AZE',
  },
  bs: {
    label: 'Bahamas',
    value: 'bs',
    flag: 'BS',
    label_alpha_3: 'BHS - Bahamas',
    value_alpha_3: 'BHS',
  },
  bh: {
    label: 'Bahrain',
    value: 'bh',
    flag: 'BH',
    label_alpha_3: 'BHR - Bahrain',
    value_alpha_3: 'BHR',
  },
  bd: {
    label: 'Bangladesh',
    value: 'bd',
    flag: 'BD',
    label_alpha_3: 'BGD - Bangladesh',
    value_alpha_3: 'BGD',
  },
  bb: {
    label: 'Barbados',
    value: 'bb',
    flag: 'BB',
    label_alpha_3: 'BRB - Barbados',
    value_alpha_3: 'BRB',
  },
  by: {
    label: 'Belarus',
    value: 'by',
    flag: 'BY',
    label_alpha_3: 'BLR - Belarus',
    value_alpha_3: 'BLR',
  },
  be: {
    label: 'Belgium',
    value: 'be',
    flag: 'BE',
    label_alpha_3: 'BEL - Belgium',
    value_alpha_3: 'BEL',
  },
  bz: {
    label: 'Belize',
    value: 'bz',
    flag: 'BZ',
    label_alpha_3: 'BLZ - Belize',
    value_alpha_3: 'BLZ',
  },
  bj: {
    label: 'Benin',
    value: 'bj',
    flag: 'BJ',
    label_alpha_3: 'BEN - Benin',
    value_alpha_3: 'BEN',
  },
  bo: {
    label: 'Bolivia',
    value: 'bo',
    flag: 'BO',
    label_alpha_3: 'BOL - Bolivia',
    value_alpha_3: 'BOL',
  },
  ba: {
    label: 'Bosnia And Herzegovina',
    value: 'ba',
    flag: 'BA',
    label_alpha_3: 'BIH - Bosnia And Herzegovina',
    value_alpha_3: 'BIH',
  },
  bw: {
    label: 'Botswana',
    value: 'bw',
    flag: 'BW',
    label_alpha_3: 'BWA - Botswana',
    value_alpha_3: 'BWA',
  },
  br: {
    label: 'Brazil',
    value: 'br',
    flag: 'BR',
    label_alpha_3: 'BRA - Brazil',
    value_alpha_3: 'BRA',
  },
  bn: {
    label: 'Brunei Darussalam',
    value: 'bn',
    flag: 'BN',
    label_alpha_3: 'BRN - Brunei Darussalam',
    value_alpha_3: 'BRN',
  },
  bg: {
    label: 'Bulgaria',
    value: 'bg',
    flag: 'BG',
    label_alpha_3: 'BGR - Bulgaria',
    value_alpha_3: 'BGR',
  },
  bf: {
    label: 'Burkina Faso',
    value: 'bf',
    flag: 'BF',
    label_alpha_3: 'BFA - Burkina Faso',
    value_alpha_3: 'BFA',
  },
  bi: {
    label: 'Burundi',
    value: 'bi',
    flag: 'BI',
    label_alpha_3: 'BDI - Burundi',
    value_alpha_3: 'BDI',
  },
  kh: {
    label: 'Cambodia',
    value: 'kh',
    flag: 'KH',
    label_alpha_3: 'KHM - Cambodia',
    value_alpha_3: 'KHM',
  },
  ky: {
    label: 'Cayman Islands',
    value: 'ky',
    flag: 'KY',
    label_alpha_3: 'CYM - Cayman Islands',
    value_alpha_3: 'CYM',
  },
  cm: {
    label: 'Cameroon',
    value: 'cm',
    flag: 'CM',
    label_alpha_3: 'CMR - Cameroon',
    value_alpha_3: 'CMR',
  },
  ca: {
    label: 'Canada',
    value: 'ca',
    flag: 'CA',
    label_alpha_3: 'CAN - Canada',
    value_alpha_3: 'CAN',
  },
  cv: {
    label: 'Cape Verde',
    value: 'cv',
    flag: 'CV',
    label_alpha_3: 'CPV - Cape Verde',
    value_alpha_3: 'CPV',
  },
  cf: {
    label: 'Central African Republic',
    value: 'cf',
    flag: 'CF',
    label_alpha_3: 'CAF - Central African Republic',
    value_alpha_3: 'CAF',
  },
  td: {
    label: 'Chad',
    value: 'td',
    flag: 'TD',
    label_alpha_3: 'TCD - Chad',
    value_alpha_3: 'TCD',
  },
  cl: {
    label: 'Chile',
    value: 'cl',
    flag: 'CL',
    label_alpha_3: 'CHL - Chile',
    value_alpha_3: 'CHL',
  },
  cn: {
    label: 'China',
    value: 'cn',
    flag: 'CN',
    label_alpha_3: 'CHN - China',
    value_alpha_3: 'CHN',
  },
  co: {
    label: 'Colombia',
    value: 'co',
    flag: 'CO',
    label_alpha_3: 'COL - Colombia',
    value_alpha_3: 'COL',
  },
  km: {
    label: 'Comoros',
    value: 'km',
    flag: 'KM',
    label_alpha_3: 'COM - Comoros',
    value_alpha_3: 'COM',
  },
  cg: {
    label: 'Congo',
    value: 'cg',
    flag: 'CG',
    label_alpha_3: 'COG - Congo',
    value_alpha_3: 'COG',
  },
  cd: {
    label: 'Congo, Democratic Republic',
    value: 'cd',
    flag: 'CD',
    label_alpha_3: 'COD - Congo, Democratic Republic',
    value_alpha_3: 'COD',
  },
  ck: {
    label: 'Cook Islands',
    value: 'ck',
    flag: 'CK',
    label_alpha_3: 'COK - Cook Islands',
    value_alpha_3: 'COK',
  },
  cr: {
    label: 'Costa Rica',
    value: 'cr',
    flag: 'CR',
    label_alpha_3: 'CRI - Costa Rica',
    value_alpha_3: 'CRI',
  },
  ci: {
    label: "Cote D'Ivoire",
    value: 'ci',
    flag: 'CI',
    label_alpha_3: 'CIV - Cote D\'Ivoire',
    value_alpha_3: 'CIV',
  },
  hr: {
    label: 'Croatia',
    value: 'hr',
    flag: 'HR',
    label_alpha_3: 'HRV - Croatia',
    value_alpha_3: 'HRV',
  },
  hk: {
    label: 'Hong Kong',
    value: 'hk',
    flag: 'HK',
    label_alpha_3: 'HKG - Hong Kong',
    value_alpha_3: 'HKG',
  },
  cu: {
    label: 'Cuba',
    value: 'cu',
    flag: 'CU',
    label_alpha_3: 'CUB - Cuba',
    value_alpha_3: 'CUB',
  },
  cy: {
    label: 'Cyprus',
    value: 'cy',
    flag: 'CY',
    label_alpha_3: 'CYP - Cyprus',
    value_alpha_3: 'CYP',
  },
  cz: {
    label: 'Czech Republic',
    value: 'cz',
    flag: 'CZ',
    label_alpha_3: 'CZE - Czech Republic',
    value_alpha_3: 'CZE',
  },
  dk: {
    label: 'Denmark',
    value: 'dk',
    flag: 'DK',
    label_alpha_3: 'DNK - Denmark',
    value_alpha_3: 'DNK',
  },
  dj: {
    label: 'Djibouti',
    value: 'dj',
    flag: 'DJ',
    label_alpha_3: 'DJI - Djibouti',
    value_alpha_3: 'DJI',
  },
  dm: {
    label: 'Dominica',
    value: 'dm',
    flag: 'DM',
    label_alpha_3: 'DMA - Dominica',
    value_alpha_3: 'DMA',
  },
  do: {
    label: 'Dominican Republic',
    value: 'do',
    flag: 'DO',
    label_alpha_3: 'DOM - Dominican Republic',
    value_alpha_3: 'DOM',
  },
  ec: {
    label: 'Ecuador',
    value: 'ec',
    flag: 'EC',
    label_alpha_3: 'ECU - Ecuador',
    value_alpha_3: 'ECU',
  },
  eg: {
    label: 'Egypt',
    value: 'eg',
    flag: 'EG',
    label_alpha_3: 'EGY - Egypt',
    value_alpha_3: 'EGY',
  },
  sv: {
    label: 'El Salvador',
    value: 'sv',
    flag: 'SV',
    label_alpha_3: 'SLV - El Salvador',
    value_alpha_3: 'SLV',
  },
  gq: {
    label: 'Equatorial Guinea',
    value: 'gq',
    flag: 'GQ',
    label_alpha_3: 'GNQ - Equatorial Guinea',
    value_alpha_3: 'GNQ',
  },
  er: {
    label: 'Eritrea',
    value: 'er',
    flag: 'ER',
    label_alpha_3: 'ERI - Eritrea',
    value_alpha_3: 'ERI',
  },
  ee: {
    label: 'Estonia',
    value: 'ee',
    flag: 'EE',
    label_alpha_3: 'EST - Estonia',
    value_alpha_3: 'EST',
  },
  et: {
    label: 'Ethiopia',
    value: 'et',
    flag: 'ET',
    label_alpha_3: 'ETH - Ethiopia',
    value_alpha_3: 'ETH',
  },
  fk: {
    label: 'Falkland Islands (Malvinas)',
    value: 'fk',
    flag: 'FK',
    label_alpha_3: 'FLK - Falkland Islands (Malvinas)',
    value_alpha_3: 'FLK',
  },
  fo: {
    label: 'Faroe Islands',
    value: 'fo',
    flag: 'FO',
    label_alpha_3: 'FRO - Faroe Islands',
    value_alpha_3: 'FRO',
  },
  fj: {
    label: 'Fiji',
    value: 'fj',
    flag: 'FJ',
    label_alpha_3: 'FJI - Fiji',
    value_alpha_3: 'FJI',
  },
  fi: {
    label: 'Finland',
    value: 'fi',
    flag: 'FI',
    label_alpha_3: 'FIN - Finland',
    value_alpha_3: 'FIN',
  },
  fr: {
    label: 'France',
    value: 'fr',
    flag: 'FR',
    label_alpha_3: 'FRA - France',
    value_alpha_3: 'FRA',
  },
  pf: {
    label: 'French Polynesia',
    value: 'pf',
    flag: 'PF',
    label_alpha_3: 'PYF - French Polynesia',
    value_alpha_3: 'PYF',
  },
  ga: {
    label: 'Gabon',
    value: 'ga',
    flag: 'GA',
    label_alpha_3: 'GAB - Gabon',
    value_alpha_3: 'GAB',
  },
  gm: {
    label: 'Gambia',
    value: 'gm',
    flag: 'GM',
    label_alpha_3: 'GMB - Gambia',
    value_alpha_3: 'GMB',
  },
  ge: {
    label: 'Georgia',
    value: 'ge',
    flag: 'GE',
    label_alpha_3: 'GEO - Georgia',
    value_alpha_3: 'GEO',
  },
  de: {
    label: 'Germany',
    value: 'de',
    flag: 'DE',
    label_alpha_3: 'DEU - Germany',
    value_alpha_3: 'DEU',
  },
  gh: {
    label: 'Ghana',
    value: 'gh',
    flag: 'GH',
    label_alpha_3: 'GHA - Ghana',
    value_alpha_3: 'GHA',
  },
  gr: {
    label: 'Greece',
    value: 'gr',
    flag: 'GR',
    label_alpha_3: 'GRC - Greece',
    value_alpha_3: 'GRC',
  },
  gl: {
    label: 'Greenland',
    value: 'gl',
    flag: 'GL',
    label_alpha_3: 'GRL - Greenland',
    value_alpha_3: 'GRL',
  },
  gd: {
    label: 'Grenada',
    value: 'gd',
    flag: 'GD',
    label_alpha_3: 'GRD - Grenada',
    value_alpha_3: 'GRD',
  },
  gu: {
    label: 'Guam',
    value: 'gu',
    flag: 'GU',
    label_alpha_3: 'GUM - Guam',
    value_alpha_3: 'GUM',
  },
  gt: {
    label: 'Guatemala',
    value: 'gt',
    flag: 'GT',
    label_alpha_3: 'GTM - Guatemala',
    value_alpha_3: 'GTM',
  },
  gg: {
    label: 'Guernsey',
    value: 'gg',
    flag: 'GG',
    label_alpha_3: 'GGY - Guernsey',
    value_alpha_3: 'GGY',
  },
  gn: {
    label: 'Guinea',
    value: 'gn',
    flag: 'GN',
    label_alpha_3: 'GIN - Guinea',
    value_alpha_3: 'GIN',
  },
  gw: {
    label: 'Guinea-Bissau',
    value: 'gw',
    flag: 'GW',
    label_alpha_3: 'GNB - Guinea-Bissau',
    value_alpha_3: 'GNB',
  },
  ht: {
    label: 'Haiti',
    value: 'ht',
    flag: 'HT',
    label_alpha_3: 'HTI - Haiti',
    value_alpha_3: 'HTI',
  },
  va: {
    label: 'Holy See (Vatican City State)',
    value: 'va',
    flag: 'VA',
    label_alpha_3: 'VAT - Holy See (Vatican City State)',
    value_alpha_3: 'VAT',
  },
  hn: {
    label: 'Honduras',
    value: 'hn',
    flag: 'HN',
    label_alpha_3: 'HND - Honduras',
    value_alpha_3: 'HND',
  },
  hu: {
    label: 'Hungary',
    value: 'hu',
    flag: 'HU',
    label_alpha_3: 'HUN - Hungary',
    value_alpha_3: 'HUN',
  },
  is: {
    label: 'Iceland',
    value: 'is',
    flag: 'IS',
    label_alpha_3: 'ISL - Iceland',
    value_alpha_3: 'ISL',
  },
  in: {
    label: 'India',
    value: 'in',
    flag: 'IN',
    label_alpha_3: 'IND - India',
    value_alpha_3: 'IND',
  },
  id: {
    label: 'Indonesia',
    value: 'id',
    flag: 'ID',
    label_alpha_3: 'IDN - Indonesia',
    value_alpha_3: 'IDN',
  },
  ir: {
    label: 'Iran, Islamic Republic Of',
    value: 'ir',
    flag: 'IR',
    label_alpha_3: 'IRN - Iran, Islamic Republic Of',
    value_alpha_3: 'IRN',
  },
  iq: {
    label: 'Iraq',
    value: 'iq',
    flag: 'IQ',
    label_alpha_3: 'IRQ - Iraq',
    value_alpha_3: 'IRQ',
  },
  ie: {
    label: 'Ireland',
    value: 'ie',
    flag: 'IE',
    label_alpha_3: 'IRL - Ireland',
    value_alpha_3: 'IRL',
  },
  im: {
    label: 'Isle Of Man',
    value: 'im',
    flag: 'IM',
    label_alpha_3: 'IMN - Isle of Man',
    value_alpha_3: 'IMN',
  },
  il: {
    label: 'Israel',
    value: 'il',
    flag: 'IL',
    label_alpha_3: 'ISR - Israel',
    value_alpha_3: 'ISR',
  },
  it: {
    label: 'Italy',
    value: 'it',
    flag: 'IT',
    label_alpha_3: 'ITA - Italy',
    value_alpha_3: 'ITA',
  },
  jm: {
    label: 'Jamaica',
    value: 'jm',
    flag: 'JM',
    label_alpha_3: 'JAM - Jamaica',
    value_alpha_3: 'JAM',
  },
  jp: {
    label: 'Japan',
    value: 'jp',
    flag: 'JP',
    label_alpha_3: 'JPN - Japan',
    value_alpha_3: 'JPN',
  },
  je: {
    label: 'Jersey',
    value: 'je',
    flag: 'JE',
    label_alpha_3: 'JEY - Jersey',
    value_alpha_3: 'JEY',
  },
  jo: {
    label: 'Jordan',
    value: 'jo',
    flag: 'JO',
    label_alpha_3: 'JOR - Jordan',
    value_alpha_3: 'JOR',
  },
  kz: {
    label: 'Kazakhstan',
    value: 'kz',
    flag: 'KZ',
    label_alpha_3: 'KAZ - Kazakhstan',
    value_alpha_3: 'KAZ',
  },
  ke: {
    label: 'Kenya',
    value: 'ke',
    flag: 'KE',
    label_alpha_3: 'KEN - Kenya',
    value_alpha_3: 'KEN',
  },
  kr: {
    label: 'Korea',
    value: 'kr',
    flag: 'KR',
    label_alpha_3: 'KOR - Korea',
    value_alpha_3: 'KOR',
  },
  kw: {
    label: 'Kuwait',
    value: 'kw',
    flag: 'KW',
    label_alpha_3: 'KWT - Kuwait',
    value_alpha_3: 'KWT',
  },
  kg: {
    label: 'Kyrgyzstan',
    value: 'kg',
    flag: 'KG',
    label_alpha_3: 'KGZ - Kyrgyzstan',
    value_alpha_3: 'KGZ',
  },
  la: {
    label: "Lao People's Democratic Republic",
    value: 'la',
    flag: 'LA',
    label_alpha_3: 'LAO - Lao People\'s Democratic Republic',
    value_alpha_3: 'LAO',
  },
  lv: {
    label: 'Latvia',
    value: 'lv',
    flag: 'LV',
    label_alpha_3: 'LVA - Latvia',
    value_alpha_3: 'LVA',
  },
  lb: {
    label: 'Lebanon',
    value: 'lb',
    flag: 'LB',
    label_alpha_3: 'LBN - Lebanon',
    value_alpha_3: 'LBN',
  },
  ls: {
    label: 'Lesotho',
    value: 'ls',
    flag: 'LS',
    label_alpha_3: 'LSO - Lesotho',
    value_alpha_3: 'LSO',
  },
  lr: {
    label: 'Liberia',
    value: 'lr',
    flag: 'LR',
    label_alpha_3: 'LBR - Liberia',
    value_alpha_3: 'LBR',
  },
  ly: {
    label: 'Libyan Arab Jamahiriya',
    value: 'ly',
    flag: 'LY',
    label_alpha_3: 'LBY - Libyan Arab Jamahiriya',
    value_alpha_3: 'LBY',
  },
  li: {
    label: 'Liechtenstein',
    value: 'li',
    flag: 'LI',
    label_alpha_3: 'LIE - Liechtenstein',
    value_alpha_3: 'LIE',
  },
  lt: {
    label: 'Lithuania',
    value: 'lt',
    flag: 'LT',
    label_alpha_3: 'LTU - Lithuania',
    value_alpha_3: 'LTU',
  },
  lu: {
    label: 'Luxembourg',
    value: 'lu',
    flag: 'LU',
    label_alpha_3: 'LUX - Luxembourg',
    value_alpha_3: 'LUX',
  },
  mk: {
    label: 'Macedonia',
    value: 'mk',
    flag: 'MK',
    label_alpha_3: 'MKD - Macedonia',
    value_alpha_3: 'MKD',
  },
  mg: {
    label: 'Madagascar',
    value: 'mg',
    flag: 'MG',
    label_alpha_3: 'MDG - Madagascar',
    value_alpha_3: 'MDG',
  },
  mw: {
    label: 'Malawi',
    value: 'mw',
    flag: 'MW',
    label_alpha_3: 'MWI - Malawi',
    value_alpha_3: 'MWI',
  },
  my: {
    label: 'Malaysia',
    value: 'my',
    flag: 'MY',
    label_alpha_3: 'MYS - Malaysia',
    value_alpha_3: 'MYS',
  },
  mv: {
    label: 'Maldives',
    value: 'mv',
    flag: 'MV',
    label_alpha_3: 'MDV - Maldives',
    value_alpha_3: 'MDV',
  },
  ml: {
    label: 'Mali',
    value: 'ml',
    flag: 'ML',
    label_alpha_3: 'MLI - Mali',
    value_alpha_3: 'MLI',
  },
  mt: {
    label: 'Malta',
    value: 'mt',
    flag: 'MT',
    label_alpha_3: 'MLT - Malta',
    value_alpha_3: 'MLT',
  },
  mh: {
    label: 'Marshall Islands',
    value: 'mh',
    flag: 'MH',
    label_alpha_3: 'MHL - Marshall Islands',
    value_alpha_3: 'MHL',
  },
  mr: {
    label: 'Mauritania',
    value: 'mr',
    flag: 'MR',
    label_alpha_3: 'MRT - Mauritania',
    value_alpha_3: 'MRT',
  },
  mx: {
    label: 'Mexico',
    value: 'mx',
    flag: 'MX',
    label_alpha_3: 'MEX - Mexico',
    value_alpha_3: 'MEX',
  },
  fm: {
    label: 'Micronesia, Federated States Of',
    value: 'fm',
    flag: 'FM',
    label_alpha_3: 'FSM - Micronesia, Federated States Of',
    value_alpha_3: 'FSM',
  },
  md: {
    label: 'Moldova',
    value: 'md',
    flag: 'MD',
    label_alpha_3: 'MDA - Moldova',
    value_alpha_3: 'MDA',
  },
  mc: {
    label: 'Monaco',
    value: 'mc',
    flag: 'MC',
    label_alpha_3: 'MCO - Monaco',
    value_alpha_3: 'MCO',
  },
  mn: {
    label: 'Mongolia',
    value: 'mn',
    flag: 'MN',
    label_alpha_3: 'MNG - Mongolia',
    value_alpha_3: 'MNG',
  },
  me: {
    label: 'Montenegro',
    value: 'me',
    flag: 'ME',
    label_alpha_3: 'MNE - Montenegro',
    value_alpha_3: 'MNE',
  },
  ms: {
    label: 'Montserrat',
    value: 'ms',
    flag: 'MS',
    label_alpha_3: 'MSR - Montserrat',
    value_alpha_3: 'MSR',
  },
  ma: {
    label: 'Morocco',
    value: 'ma',
    flag: 'MA',
    label_alpha_3: 'MAR - Morocco',
    value_alpha_3: 'MAR',
  },
  mz: {
    label: 'Mozambique',
    value: 'mz',
    flag: 'MZ',
    label_alpha_3: 'MOZ - Mozambique',
    value_alpha_3: 'MOZ',
  },
  mm: {
    label: 'Myanmar',
    value: 'mm',
    flag: 'MM',
    label_alpha_3: 'MMR - Myanmar',
    value_alpha_3: 'MMR',
  },
  na: {
    label: 'Namibia',
    value: 'na',
    flag: 'NA',
    label_alpha_3: 'NAM - Namibia',
    value_alpha_3: 'NAM',
  },
  nr: {
    label: 'Nauru',
    value: 'nr',
    flag: 'NR',
    label_alpha_3: 'NRU - Nauru',
    value_alpha_3: 'NRU',
  },
  np: {
    label: 'Nepal',
    value: 'np',
    flag: 'NP',
    label_alpha_3: 'NPL - Nepal',
    value_alpha_3: 'NPL',
  },
  nl: {
    label: 'Netherlands',
    value: 'nl',
    flag: 'NL',
    label_alpha_3: 'NLD - Netherlands',
    value_alpha_3: 'NLD',
  },
  nz: {
    label: 'New Zealand',
    value: 'nz',
    flag: 'NZ',
    label_alpha_3: 'NZL - New Zealand',
    value_alpha_3: 'NZL',
  },
  ni: {
    label: 'Nicaragua',
    value: 'ni',
    flag: 'NI',
    label_alpha_3: 'NIC - Nicaragua',
    value_alpha_3: 'NIC',
  },
  ne: {
    label: 'Niger',
    value: 'ne',
    flag: 'NE',
    label_alpha_3: 'NER - Niger',
    value_alpha_3: 'NER',
  },
  ng: {
    label: 'Nigeria',
    value: 'ng',
    flag: 'NG',
    label_alpha_3: 'NGA - Nigeria',
    value_alpha_3: 'NGA',
  },
  nu: {
    label: 'Niue',
    value: 'nu',
    flag: 'NU',
    label_alpha_3: 'NIU - Niue',
    value_alpha_3: 'NIU',
  },
  mp: {
    label: 'Northern Mariana Islands',
    value: 'mp',
    flag: 'MP',
    label_alpha_3: 'MNP - Northern Mariana Islands',
    value_alpha_3: 'MNP',
  },
  no: {
    label: 'Norway',
    value: 'no',
    flag: 'NO',
    label_alpha_3: 'NOR - Norway',
    value_alpha_3: 'NOR',
  },
  om: {
    label: 'Oman',
    value: 'om',
    flag: 'OM',
    label_alpha_3: 'OMN - Oman',
    value_alpha_3: 'OMN',
  },
  pk: {
    label: 'Pakistan',
    value: 'pk',
    flag: 'PK',
    label_alpha_3: 'PAK - Pakistan',
    value_alpha_3: 'PAK',
  },
  pw: {
    label: 'Palau',
    value: 'pw',
    flag: 'PW',
    label_alpha_3: 'PLW - Palau',
    value_alpha_3: 'PLW',
  },
  ps: {
    label: 'Palestinian Territory, Occupied',
    value: 'ps',
    flag: 'PS',
    label_alpha_3: 'PSE - Palestinian Territory, Occupied',
    value_alpha_3: 'PSE',
  },
  pa: {
    label: 'Panama',
    value: 'pa',
    flag: 'PA',
    label_alpha_3: 'PAN - Panama',
    value_alpha_3: 'PAN',
  },
  pg: {
    label: 'Papua New Guinea',
    value: 'pg',
    flag: 'PG',
    label_alpha_3: 'PNG - Papua New Guinea',
    value_alpha_3: 'PNG',
  },
  py: {
    label: 'Paraguay',
    value: 'py',
    flag: 'PY',
    label_alpha_3: 'PRY - Paraguay',
    value_alpha_3: 'PRY',
  },
  pe: {
    label: 'Peru',
    value: 'pe',
    flag: 'PE',
    label_alpha_3: 'PER - Peru',
    value_alpha_3: 'PER',
  },
  ph: {
    label: 'Philippines',
    value: 'ph',
    flag: 'PH',
    label_alpha_3: 'PHL - Philippines',
    value_alpha_3: 'PHL',
  },
  pn: {
    label: 'Pitcairn',
    value: 'pn',
    flag: 'PN',
    label_alpha_3: 'PCN - Pitcairn',
    value_alpha_3: 'PCN',
  },
  pl: {
    label: 'Poland',
    value: 'pl',
    flag: 'PL',
    label_alpha_3: 'POL - Poland',
    value_alpha_3: 'POL',
  },
  pt: {
    label: 'Portugal',
    value: 'pt',
    flag: 'PT',
    label_alpha_3: 'PRT - Portugal',
    value_alpha_3: 'PRT',
  },
  pr: {
    label: 'Puerto Rico',
    value: 'pr',
    flag: 'PR',
    label_alpha_3: 'PRI - Puerto Rico',
    value_alpha_3: 'PRI',
  },
  ro: {
    label: 'Romania',
    value: 'ro',
    flag: 'RO',
    label_alpha_3: 'ROU - Romania',
    value_alpha_3: 'ROU',
  },
  ru: {
    label: 'Russian Federation',
    value: 'ru',
    flag: 'RU',
    label_alpha_3: 'RUS - Russian Federation',
    value_alpha_3: 'RUS',
  },
  rw: {
    label: 'Rwanda',
    value: 'rw',
    flag: 'RW',
    label_alpha_3: 'RWA - Rwanda',
    value_alpha_3: 'RWA',
  },
  bl: {
    label: 'Saint Barthelemy',
    value: 'bl',
    flag: 'BL',
    label_alpha_3: 'BLM - Saint Barthelemy',
    value_alpha_3: 'BLM',
  },
  kn: {
    label: 'Saint Kitts And Nevis',
    value: 'kn',
    flag: 'KN',
    label_alpha_3: 'KNA - Saint Kitts And Nevis',
    value_alpha_3: 'KNA',
  },
  lc: {
    label: 'Saint Lucia',
    value: 'lc',
    flag: 'LC',
    label_alpha_3: 'LCA - Saint Lucia',
    value_alpha_3: 'LCA',
  },
  vc: {
    label: 'Saint Vincent And Grenadines',
    value: 'vc',
    flag: 'VC',
    label_alpha_3: 'VCT - Saint Vincent And Grenadines',
    value_alpha_3: 'VCT',
  },
  ws: {
    label: 'Samoa',
    value: 'ws',
    flag: 'WS',
    label_alpha_3: 'WSM - Samoa',
    value_alpha_3: 'WSM',
  },
  sm: {
    label: 'San Marino',
    value: 'sm',
    flag: 'SM',
    label_alpha_3: 'SMR - San Marino',
    value_alpha_3: 'SMR',
  },
  st: {
    label: 'Sao Tome And Principe',
    value: 'st',
    flag: 'ST',
    label_alpha_3: 'STP - Sao Tome And Principe',
    value_alpha_3: 'STP',
  },
  sa: {
    label: 'Saudi Arabia',
    value: 'sa',
    flag: 'SA',
    label_alpha_3: 'SAU - Saudi Arabia',
    value_alpha_3: 'SAU',
  },
  sn: {
    label: 'Senegal',
    value: 'sn',
    flag: 'SN',
    label_alpha_3: 'SEN - Senegal',
    value_alpha_3: 'SEN',
  },
  rs: {
    label: 'Serbia',
    value: 'rs',
    flag: 'RS',
    label_alpha_3: 'SRB - Serbia',
    value_alpha_3: 'SRB',
  },
  sc: {
    label: 'Seychelles',
    value: 'sc',
    flag: 'SC',
    label_alpha_3: 'SYC - Seychelles',
    value_alpha_3: 'SYC',
  },
  sl: {
    label: 'Sierra Leone',
    value: 'sl',
    flag: 'SL',
    label_alpha_3: 'SLE - Sierra Leone',
    value_alpha_3: 'SLE',
  },
  sg: {
    label: 'Singapore',
    value: 'sg',
    flag: 'SG',
    label_alpha_3: 'SGP - Singapore',
    value_alpha_3: 'SGP',
  },
  sk: {
    label: 'Slovakia',
    value: 'sk',
    flag: 'SK',
    label_alpha_3: 'SVK - Slovakia',
    value_alpha_3: 'SVK',
  },
  si: {
    label: 'Slovenia',
    value: 'si',
    flag: 'SI',
    label_alpha_3: 'SVN - Slovenia',
    value_alpha_3: 'SVN',
  },
  sb: {
    label: 'Solomon Islands',
    value: 'sb',
    flag: 'SB',
    label_alpha_3: 'SLB - Solomon Islands',
    value_alpha_3: 'SLB',
  },
  so: {
    label: 'Somalia',
    value: 'so',
    flag: 'SO',
    label_alpha_3: 'SOM - Somalia',
    value_alpha_3: 'SOM',
  },
  za: {
    label: 'South Africa',
    value: 'za',
    flag: 'ZA',
    label_alpha_3: 'ZAF - South Africa',
    value_alpha_3: 'ZAF',
  },
  es: {
    label: 'Spain',
    value: 'es',
    flag: 'ES',
    label_alpha_3: 'ESP - Spain',
    value_alpha_3: 'ESP',
  },
  lk: {
    label: 'Sri Lanka',
    value: 'lk',
    flag: 'LK',
    label_alpha_3: 'LKA - Sri Lanka',
    value_alpha_3: 'LKA',
  },
  sd: {
    label: 'Sudan',
    value: 'sd',
    flag: 'SD',
    label_alpha_3: 'SDN - Sudan',
    value_alpha_3: 'SDN',
  },
  ch: {
    label: 'Switzerland',
    value: 'ch',
    flag: 'CH',
    label_alpha_3: 'CHE - Switzerland',
    value_alpha_3: 'CHE',
  },
  sy: {
    label: 'Syrian Arab Republic',
    value: 'sy',
    flag: 'SY',
    label_alpha_3: 'SYR - Syrian Arab Republic',
    value_alpha_3: 'SYR',
  },
  tj: {
    label: 'Tajikistan',
    value: 'tj',
    flag: 'TJ',
    label_alpha_3: 'TJK - Tajikistan',
    value_alpha_3: 'TJK',
  },
  tz: {
    label: 'Tanzania',
    value: 'tz',
    flag: 'TZ',
    label_alpha_3: 'TZA - Tanzania',
    value_alpha_3: 'TZA',
  },
  th: {
    label: 'Thailand',
    value: 'th',
    flag: 'TH',
    label_alpha_3: 'THA - Thailand',
    value_alpha_3: 'THA',
  },
  tl: {
    label: 'Timor-Leste',
    value: 'tl',
    flag: 'TL',
    label_alpha_3: 'TLS - Timor-Leste',
    value_alpha_3: 'TLS',
  },
  tg: {
    label: 'Togo',
    value: 'tg',
    flag: 'TG',
    label_alpha_3: 'TGO - Togo',
    value_alpha_3: 'TGO',
  },
  tk: {
    label: 'Tokelau',
    value: 'tk',
    flag: 'TK',
    label_alpha_3: 'TKL - Tokelau',
    value_alpha_3: 'TKL',
  },
  to: {
    label: 'Tonga',
    value: 'to',
    flag: 'TO',
    label_alpha_3: 'TON - Tonga',
    value_alpha_3: 'TON',
  },
  tt: {
    label: 'Trinidad And Tobago',
    value: 'tt',
    flag: 'TT',
    label_alpha_3: 'TTO - Trinidad And Tobago',
    value_alpha_3: 'TTO',
  },
  tn: {
    label: 'Tunisia',
    value: 'tn',
    flag: 'TN',
    label_alpha_3: 'TUN - Tunisia',
    value_alpha_3: 'TUN',
  },
  tr: {
    label: 'Turkey',
    value: 'tr',
    flag: 'TR',
    label_alpha_3: 'TUR - Turkey',
    value_alpha_3: 'TUR',
  },
  tm: {
    label: 'Turkmenistan',
    value: 'tm',
    flag: 'TM',
    label_alpha_3: 'TKM - Turkmenistan',
    value_alpha_3: 'TKM',
  },
  tc: {
    label: 'Turks And Caicos Islands',
    value: 'tc',
    flag: 'TC',
    label_alpha_3: 'TCA - Turks And Caicos Islands',
    value_alpha_3: 'TCA',
  },
  tv: {
    label: 'Tuvalu',
    value: 'tv',
    flag: 'TV',
    label_alpha_3: 'TUV - Tuvalu',
    value_alpha_3: 'TUV',
  },
  ug: {
    label: 'Uganda',
    value: 'ug',
    flag: 'UG',
    label_alpha_3: 'UGA - Uganda',
    value_alpha_3: 'UGA',
  },
  ua: {
    label: 'Ukraine',
    value: 'ua',
    flag: 'UA',
    label_alpha_3: 'UKR - Ukraine',
    value_alpha_3: 'UKR',
  },
  uy: {
    label: 'Uruguay',
    value: 'uy',
    flag: 'UY',
    label_alpha_3: 'URY - Uruguay',
    value_alpha_3: 'URY',
  },
  vg: {
    label: 'British Virgin Islands',
    value: 'vg',
    flag: 'VG',
    label_alpha_3: 'VGB - British Virgin Islands',
    value_alpha_3: 'VGB',
  },
  ae: {
    label: 'United Arab Emirates',
    value: 'ae',
    flag: 'AE',
    label_alpha_3: 'ARE - United Arab Emirates',
    value_alpha_3: 'ARE',
  },
  gb: {
    label: 'United Kingdom',
    value: 'gb',
    flag: 'GB',
    label_alpha_3: 'GBR - United Kingdom',
    value_alpha_3: 'GBR',
  },
  us: {
    label: 'United States',
    value: 'us',
    flag: 'US',
    label_alpha_3: 'USA - United States',
    value_alpha_3: 'USA',
  },
  uz: {
    label: 'Uzbekistan',
    value: 'uz',
    flag: 'UZ',
    label_alpha_3: 'UZB - Uzbekistan',
    value_alpha_3: 'UZB',
  },
  vu: {
    label: 'Vanuatu',
    value: 'vu',
    flag: 'VU',
    label_alpha_3: 'VUT - Vanuatu',
    value_alpha_3: 'VUT',
  },
  ve: {
    label: 'Venezuela',
    value: 've',
    flag: 'VE',
    label_alpha_3: 'VEN - Venezuela',
    value_alpha_3: 'VEN',
  },
  vn: {
    label: 'Viet Nam',
    value: 'vn',
    flag: 'VN',
    label_alpha_3: 'VNM - Viet Nam',
    value_alpha_3: 'VNM',
  },
  ye: {
    label: 'Yemen',
    value: 'ye',
    flag: 'YE',
    label_alpha_3: 'YEM - Yemen',
    value_alpha_3: 'YEM',
  },
  zm: {
    label: 'Zambia',
    value: 'zm',
    flag: 'ZM',
    label_alpha_3: 'ZMB - Zambia',
    value_alpha_3: 'ZMB',
  },
  zw: {
    label: 'Zimbabwe',
    value: 'zw',
    flag: 'ZW',
    label_alpha_3: 'ZWE - Zimbabwe',
    value_alpha_3: 'ZWE',
  },
};

export const CountriesOptions = Object.values(CountriesObject);
